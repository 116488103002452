import React from 'react'
import { ApolloProvider } from 'react-apollo'
import { ApolloProvider as ApolloHooksProvider } from 'react-apollo-hooks'
import { ThemeProvider } from 'theme-ui'

import client from './apollo'
import theme from './theme'

const Providers = props => (
  <ThemeProvider theme={theme}>
    <ApolloProvider client={client}>
      <ApolloHooksProvider client={client}>
        {props.children}
      </ApolloHooksProvider>
    </ApolloProvider>
  </ThemeProvider>
)

export default Providers
