import React from 'react'
import styled from '@emotion/styled/macro'
import { keyframes } from '@emotion/core'

const rotation = keyframes`

  0% {
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
            transform: perspective(120px) rotateX(0deg) rotateY(0deg); }
  50% {
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
            transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg); }
  100% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
            transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg); } }


`

const CssLoader = styled.div`
  width: 40px;
  height: 40px;
  background-color: #333;
  margin: 40px auto;
  -webkit-animation: ${rotation} 1.2s infinite ease-in-out;
  animation: ${rotation} 1.2s infinite ease-in-out;
`

const Loader = () => <CssLoader />

export default Loader
