import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { InMemoryCache } from 'apollo-cache-inmemory'

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_HASURA_ENDPOINT,
})

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    'X-Hasura-Role': process.env.REACT_APP_HASURA_ROLE,
  },
}))

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
})

export default client
