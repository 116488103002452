import React from 'react'
import { Layout, Header, Main, Container, Footer } from 'theme-ui'

const TrafficLayout = props => (
  <Layout>
    <Header />
    <Main>
      <Container>{props.children}</Container>
    </Main>
    <Footer />
  </Layout>
)

export default TrafficLayout
