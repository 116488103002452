import React from 'react'
import _ from 'lodash'
import dayjs from 'dayjs'
import { useQuery } from 'react-apollo-hooks'
import gql from 'graphql-tag'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts'

import Loader from './components/Loader'
import { Box, Flex } from 'theme-ui'

const NB_OF_DAYS = 60

const GET_ANALYTICS = gql`
  query($website: String!) {
    #   analytics(
    #     order_by: { created_at: asc }
    #     where: { website: { domain: { _eq: $website } } }
    #   ) {
    #     id
    #     created_at
    #     referrer
    #     device_width
    #     user_agent
    #   }

    websites(where: { domain: { _eq: $website } }) {
      domain
      analytics_by_day(
        order_by: { create_at_day: desc }
        limit: ${NB_OF_DAYS + 1}
      ) {
        count
        create_at_day
      }
      analytics_by_day_aggregate (
        order_by: { create_at_day: desc }
        limit: ${NB_OF_DAYS + 1}
      ){
        aggregate {
          sum {
            count
          }
        }
      }
    }
  }
`

const Traffic = ({ website }) => {
  const { data, error, loading } = useQuery(GET_ANALYTICS, {
    variables: { website },
  })
  if (loading) {
    return <Loader />
  }

  if (error) {
    return <div>Error! {error.message}</div>
  }

  // const analytics = _.map(
  //   _.groupBy(data.analytics, a => {
  //     const date = new Date(a.created_at)
  //     return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
  //   }),
  //   (analytic, day) => ({
  //     date: day,
  //     count: _.size(analytic),
  //   }),
  // )

  const analyticsByDay = _.get(data, 'websites[0].analytics_by_day', [])
  const totalCount = _.get(
    data,
    'websites[0].analytics_by_day_aggregate.aggregate.sum.count',
    0,
  )
  const today = dayjs()
  const analytics = _.range(NB_OF_DAYS, -1)
    .map(nbOfDay => today.subtract(nbOfDay, 'day'))
    .map(d => {
      const date = d.format('YYYY-MM-DD')
      return {
        date,
        count: _.get(
          _.find(analyticsByDay, { create_at_day: date }),
          'count',
          0,
        ),
      }
    })

  return (
    <Flex alignItems="center" flexDirection="column">
      <Flex my={5}>
        <LineChart width={730} height={250} data={analytics}>
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          {/* <XAxis dataKey="date" /> */}
          {/* <YAxis /> */}
          <Tooltip content={<CustomTooltip />} />
          {/* <Legend /> */}
          <Line type="monotone" dataKey="count" stroke="#333" />
        </LineChart>
      </Flex>
      <Flex>{website}</Flex>
      <Flex>
        <small>
          Total in the last {NB_OF_DAYS} days: {totalCount}
        </small>
      </Flex>
    </Flex>
  )
}

const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    const { count, date } = _.get(payload, '0.payload', {})
    return (
      <div>
        <p>date: {date}</p>
        <p>views: {count}</p>
      </div>
    )
  }

  return null
}

export default Traffic
