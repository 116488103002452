import React from 'react'
import _ from 'lodash'
import './App.css'
import TrafficLayout from './TrafficLayout'
import Providers from './Providers'
import Traffic from './Traffic'

function App() {
  const website = _.replace(window.location.pathname, '/', '')
  return (
    <Providers>
      <TrafficLayout>
        {!_.size(website) ? (
          <a href="achkouyan.com">achkouyan.com</a>
        ) : (
          <Traffic website={website} />
        )}
      </TrafficLayout>
    </Providers>
  )
}

export default App
